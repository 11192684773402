import React from 'react'
import SssSection from '../components/SssSection';
import VideoGallery from '../components/VideoGallery';
const SSS = () => {
  return (
    <div>
      <SssSection />
      <VideoGallery />
    </div>
  )
}

export default SSS
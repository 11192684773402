import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles
import './PartnersSection.css'; // Optional CSS for custom styling
import hstmobil from '../assets/logos/hstmobilsiyah.png';
import gobro from '../assets/logos/gobro-logo.png';
import secretValor from '../assets/logos/secretvalor.png';
import netHayat from '../assets/logos/nethayat.png';
import hygia from '../assets/logos/hygiamed.png';
import solley from '../assets/logos/solley.png';
import Logo from '../assets/logos/bilisimsiyahlogo.png';
import { useTranslation } from 'react-i18next'; // Çeviri hook'u

const PartnersSection = () => {
  const { t } = useTranslation(); // Çeviri için i18n kullanımı

  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration in milliseconds
      once: true // Whether animation should happen only once or every time you scroll up/down
    });
  }, []);

  return (
    // SEO için section etiketi kullanımı
    <section className="partners-section my-5">
      <Container>
        {/* Ana başlık için <h1> kullanımı */}
        <div className="blog-header text-center mb-4" data-aos="fade-left">
          <img src={Logo} alt="HST Bilişim Teknolojileri Logo" className="blog-logo" />
          <h1 className="blog-title">{t('partners.title')}</h1> {/* Başlık çevirisi */}
        </div>
        <Row className="justify-content-center">
          {/* Her logo için anlamlı alt etiketler eklendi */}
          <Col xs={6} sm={4} md={2} className="text-center mb-3" data-aos="zoom-in">
            <img src={hstmobil} alt="HST Mobil - Mobil uygulama çözümleri" className="img-fluid partner-logo uniform-size" />
          </Col>
          <Col xs={6} sm={4} md={2} className="text-center mb-3" data-aos="zoom-in">
            <img src={gobro} alt="GoBro - Yazılım ve danışmanlık hizmetleri" className="img-fluid partner-logo uniform-size" />
          </Col>
          <Col xs={6} sm={4} md={2} className="text-center mb-3" data-aos="zoom-in">
            <img src={secretValor} alt="Secret Valor - Güvenlik çözümleri" className="img-fluid partner-logo uniform-size" />
          </Col>
          <Col xs={6} sm={4} md={2} className="text-center mb-3" data-aos="zoom-in">
            <img src={netHayat} alt="Net Hayat - İnternet ve telekom hizmetleri" className="img-fluid partner-logo uniform-size" />
          </Col>
          <Col xs={6} sm={4} md={2} className="text-center mb-3" data-aos="zoom-in">
            <img src={hygia} alt="HygiaMed - Sağlık teknolojileri" className="img-fluid partner-logo uniform-size" />
          </Col>
          <Col xs={6} sm={4} md={2} className="text-center mb-3" data-aos="zoom-in">
            <img src={solley} alt="Solley - Enerji çözümleri" className="img-fluid partner-logo uniform-size" />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default PartnersSection;

import React, { useEffect } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import AOS from 'aos';
import 'aos/dist/aos.css'; 
import './ContactForm.css';
import { useTranslation } from 'react-i18next'; 

const ContactForm = () => {
  const { t } = useTranslation();

  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: 'ease-in-out',
      once: true,
    });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    
    const formData = {
      firstName: e.target.firstName.value,
      lastName: e.target.lastName.value,
      companyName: e.target.companyName.value,
      address: e.target.address.value,
      email: e.target.email.value,
      phone: e.target.phone.value,
      howDidYouHear: e.target.howDidYouHear.value,
      solutions: Array.from(e.target.solutions).filter(opt => opt.checked).map(opt => opt.value),
      message: e.target.message.value
    };
  
    fetch('https://www.hstbilisim.com/send-email.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    })
    .then(response => response.json())
    .then(data => {
      if (data.success) {
        alert('Email sent successfully!');
      } else {
        alert('Error sending email.');
      }
    })
    .catch((error) => {
      console.error('Error:', error);
    });
  };

  return (
    <div className='contact-form-section'>
      <div className="contact-us-section container">
        <Row>
          {/* Sol Kolon: İletişim Bilgileri */}
          <Col md={6} className="contact-info" data-aos="fade-right">
            <h1>{t('contact.title')}</h1> {/* H1 etiketi */}
            <p>{t('contact.description')}</p>

            <div className="contact-details">
              <div className="contact-item">
                <FontAwesomeIcon icon={faPhone} /> 
                <span>
                  <a href='tel:+90 212 706 5166' style={{ textDecoration: 'none', color: '#333' }}>0 212 706 5166</a>
                </span>
              </div>
              <div className="contact-item">
                <FontAwesomeIcon icon={faEnvelope} /> 
                <span>info@hstbilisim.com</span>
              </div>
              <div className="contact-item">
                <FontAwesomeIcon icon={faMapMarkerAlt} /> 
                <span>{t('contact.addressLink')}</span>
              </div>
            </div>

            <div className="next-steps">
              <h2>{t('contact.nextStepsTitle')}</h2> {/* H2 etiketi */}
              <div className="steps">
                <div><span>1</span> {t('contact.step1')}</div>
                <div><span>2</span> {t('contact.step2')}</div>
                <div><span>3</span> {t('contact.step3')}</div>
              </div>
            </div>
          </Col>

          {/* Sağ Kolon: Form Bölümü */}
          <Col md={6} className="form-section" data-aos="fade-left">
            <h2>{t('contact.formTitle')}</h2> {/* H2 etiketi */}
            <Form>
              <Row>
                <Col md={6}>
                  <Form.Group controlId="firstName">
                    <Form.Label>{t('contact.firstName')} *</Form.Label>
                    <Form.Control type="text" required />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="lastName">
                    <Form.Label>{t('contact.lastName')} *</Form.Label>
                    <Form.Control type="text" required />
                  </Form.Group>
                </Col>
              </Row>

              <Form.Group controlId="companyName">
                <Form.Label>{t('contact.companyName')} *</Form.Label>
                <Form.Control type="text" required />
              </Form.Group>

              <Form.Group controlId="address">
                <Form.Label>{t('contact.address')} *</Form.Label>
                <Form.Control type="text" required />
              </Form.Group>

              <Form.Group controlId="email">
                <Form.Label>{t('contact.email')} *</Form.Label>
                <Form.Control type="email" required />
              </Form.Group>

              <Form.Group controlId="phone">
                <Form.Label>{t('contact.phone')} *</Form.Label>
                <Form.Control type="text" required />
              </Form.Group>

              <Form.Group controlId="howDidYouHear">
                <Form.Label>{t('contact.howDidYouHear')}</Form.Label>
                <Form.Control as="select">
                  <option>{t('contact.option1')}</option>
                  <option>{t('contact.option2')}</option>
                  <option>{t('contact.option3')}</option>
                </Form.Control>
              </Form.Group>

              <Form.Group controlId="solutions">
                <Form.Label>{t('contact.solutionsInterest')}</Form.Label>
                <div className="solution-options">
                  <Form.Check type="checkbox" label={t('contact.solution1')} />
                  <Form.Check type="checkbox" label={t('contact.solution2')} />
                  <Form.Check type="checkbox" label={t('contact.solution3')} />
                  <Form.Check type="checkbox" label={t('contact.solution4')} />
                  <Form.Check type="checkbox" label={t('contact.solution5')} />
                  <Form.Check type="checkbox" label={t('contact.solution6')} />
                  <Form.Check type="checkbox" label={t('contact.solution7')} />
                  <Form.Check type="checkbox" label={t('contact.solution8')} />
                  <Form.Check type="checkbox" label={t('contact.solution9')} />
                  <Form.Check type="checkbox" label={t('contact.solution10')} />
                  <Form.Check type="checkbox" label={t('contact.solution11')} />
                </div>
              </Form.Group>

              <Form.Group controlId="message">
                <Form.Label>{t('contact.message')}</Form.Label>
                <Form.Control as="textarea" rows={4} />
              </Form.Group>

              <Button variant="success" type="submit" className="mt-4">
                {t('contact.submitButton')}
              </Button>
            </Form>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ContactForm;

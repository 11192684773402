import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useTranslation } from 'react-i18next'; // i18n için import
import './HeroSection.css'; 
import man from '../assets/banners/man.png';

const HeroSection = () => {
  const { t } = useTranslation(); // useTranslation hook'u kullanıldı

  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: 'ease-in-out',
      once: true,
    });
  }, []);

  return (
    <div className="hero-section">
      <Container>
        <Row className="align-items-center">
          <Col md={6} className="text-section" data-aos="fade-up">
            <p className="lead">
              <strong>{t('hero.subheading')}</strong>
            </p>
            {/* Ana başlık için <h1> kullanımı */}
            <h1>{t('hero.heading')}</h1>
            <div className="action-buttons mt-4">
              {/* İlgili linkler <a> etiketi ile kullanılmalı */}
              <a href="/contact" className="btn btn-success me-3">{t('hero.contactUs')}</a>
              <a href="/callback" className="btn btn-link text-dark">{t('hero.callBack')} →</a>
            </div>
          </Col>

          <Col md={6} className="image-section" data-aos="fade-left">
            {/* Görsele anlamlı bir alt etiketi verildi */}
            <img
              src={man}
              alt="A hst bilişim worker with a laptop"
              className="img-fluid"
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default HeroSection;

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next'; // i18n import
import { Modal, Button, Container, Row, Col } from 'react-bootstrap';
import Flag from 'react-world-flags'; // Flag bileşeni
import './CookieConsent.css';

const CookieConsent = () => {
  const { t, i18n } = useTranslation(); // Translation hook ve i18n
  const [showModal, setShowModal] = useState(false); // Pop-up modal görünürlüğü için state

  useEffect(() => {
    // localStorage'dan çerez onayı durumu kontrol edilir
    const consent = localStorage.getItem('cookieConsent');
    if (!consent) {
      setShowModal(true); // Eğer kullanıcı onay vermemişse, modal gösterilir
    }
  }, []);

  const handleAcceptAll = () => {
    // Çerezleri kabul et ve pop-up'ı kapat
    localStorage.setItem('cookieConsent', 'accepted');
    setShowModal(false);
  };

  const handleRejectAll = () => {
    // Bütün çerezleri sil ve pop-up'ı kapat
    document.cookie.split(";").forEach((cookie) => {
      document.cookie = cookie
        .replace(/^ +/, "")
        .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
    });
    localStorage.setItem('cookieConsent', 'rejected');
    setShowModal(false); // Pop-up kapanır
  };

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang); // Dili değiştir
  };

  return (
    showModal && ( // Banner görünürken çalışır
      <div className="cookie-consent">
        <Modal show={showModal} onHide={handleRejectAll} backdrop="static" keyboard={false} centered>
          <Modal.Header>
            <Modal.Title>{t('cookieConsent.title')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{t('cookieConsent.description')}</p>
            <Container>
              <Row className="justify-content-end">
                <Col xs="auto">
                  <Flag code="TR" height="20" className="me-2 cursor-pointer" onClick={() => changeLanguage('tr')} />
                  <Flag code="GB" height="20" className="me-2 cursor-pointer" onClick={() => changeLanguage('en')} />
                  <Flag code="SA" height="20" className="me-2 cursor-pointer" onClick={() => changeLanguage('ar')} />
                  <Flag code="FR" height="20" className="me-2 cursor-pointer" onClick={() => changeLanguage('fr')} />
                  <Flag code="DE" height="20" className="me-2 cursor-pointer" onClick={() => changeLanguage('de')} />
                  <Flag code="ES" height="20" className="me-2 cursor-pointer" onClick={() => changeLanguage('es')} />
                </Col>
              </Row>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={handleAcceptAll}>
              {t('cookieConsent.acceptAll')}
            </Button>
            <Button variant="danger" onClick={handleRejectAll}>
              {t('cookieConsent.rejectAll')}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  );
};

export default CookieConsent;

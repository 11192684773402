import React, { useState, useEffect } from 'react';
import Logo from '../assets/logos/bilisimsiyahlogo.png';
import { Container, Form, Button, Row, Col, ProgressBar, CloseButton } from 'react-bootstrap';
import './Career.css'; // Custom CSS dosyanızı eklemeyi unutmayın

const Career = () => {
  const [formData, setFormData] = useState({
    name: '',
    surname: '',
    email: '',
    phone: '',
    position: '', 
    cv: null,
    cvName: '',
  });

  const [isFormValid, setIsFormValid] = useState(false); 
  const [uploadProgress, setUploadProgress] = useState(0); 
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [timeLeft, setTimeLeft] = useState(0); 
  const [isFileValid, setIsFileValid] = useState(true); 

  const restrictedChars = /[`!@#$%^&*()_+=[\]{};:"\\|,.<>/?~]/; 

  useEffect(() => {
    const isValid =
      formData.name.trim() &&
      formData.surname.trim() &&
      formData.email.includes('@') &&
      formData.phone.trim() &&
      formData.position.trim() && 
      formData.cv &&
      isFileValid;
    setIsFormValid(isValid);
  }, [formData, isFileValid]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    
    if (restrictedChars.test(value)) {
      return; 
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const validFormats = ['application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']; // PDF ve DOCX
      if (validFormats.includes(file.type)) {
        setIsFileValid(true); 

        setFormData((prevData) => ({
          ...prevData,
          cv: file,
          cvName: file.name,
        }));

        const uploadInterval = setInterval(() => {
          setUploadProgress((prevProgress) => {
            if (prevProgress >= 100) {
              clearInterval(uploadInterval);
              return 100;
            }
            return prevProgress + 10; 
          });
        }, 100);
      } else {
        setIsFileValid(false); 
        setFormData((prevData) => ({
          ...prevData,
          cv: null,
          cvName: '', 
        }));
      }
    }
  };

  const handleFileRemove = () => {
    setFormData((prevData) => ({
      ...prevData,
      cv: null,
      cvName: '',
    }));
    setUploadProgress(0); 
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isFormValid && !isSubmitting && timeLeft === 0) {
      console.log('Form Data:', formData);
      setIsSubmitting(true);
      setTimeLeft(60);

      const timer = setInterval(() => {
        setTimeLeft((prevTimeLeft) => {
          if (prevTimeLeft === 1) {
            clearInterval(timer);
            setIsSubmitting(false); 
            return 0;
          }
          return prevTimeLeft - 1;
        });
      }, 1000);
    }
  };

  return (
    <div>
      {/* Header kısmı */}
      <div className="blog-header text-center mb-4 mt-5" data-aos="fade-left">
        <img src={Logo} alt="HST BİLİŞİM Logo" className="blog-logo" />
        <h1 className="blog-title">Kariyer</h1>
      </div>

      {/* Kariyer Formu */}
      <section className="career-form-section mt-5" data-aos="fade-up">
        <Container>
          <h2 className="text-center mb-4">Kariyer Başvuru Formu</h2>
          <Form onSubmit={handleSubmit} className="career-form rounded p-4">
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="formName">
                  <Form.Label>İsim</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    placeholder="İsminizi girin"
                    required
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="formSurname">
                  <Form.Label>Soyisim</Form.Label>
                  <Form.Control
                    type="text"
                    name="surname"
                    value={formData.surname}
                    onChange={handleInputChange}
                    placeholder="Soyisminizi girin"
                    required
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="formEmail">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    placeholder="Email adresinizi girin"
                    required
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="formPhone">
                  <Form.Label>Telefon</Form.Label>
                  <Form.Control
                    type="text"
                    name="phone"
                    value={formData.phone}
                    onChange={handleInputChange}
                    placeholder="Telefon numaranızı girin"
                    required
                  />
                </Form.Group>
              </Col>
            </Row>

            {/* Position alanı artık bir text input olacak */}
            <Form.Group className="mb-3" controlId="formPosition">
              <Form.Label>Başvurmak İstediğiniz Alan</Form.Label>
              <Form.Control
                type="text"
                name="position"
                value={formData.position}
                onChange={handleInputChange}
                placeholder="Başvurmak istediğiniz alanı yazın"
                required
              />
            </Form.Group>

            <Form.Group className="mb-4" controlId="formFile">
              <Form.Label>CV Yükle</Form.Label>
              <div className="d-flex align-items-center">
                <Form.Control
                  type="file"
                  name="cv"
                  onChange={handleFileChange}
                  accept=".pdf,.doc,.docx"
                  required
                />
                {formData.cvName && (
                  <div className="ms-3 d-flex align-items-center">
                    <span>{formData.cvName}</span>
                    <CloseButton className="ms-2" onClick={handleFileRemove} /> 
                  </div>
                )}
              </div>
              <ProgressBar now={uploadProgress} className="mt-2" label={`${uploadProgress}%`} />
              {!isFileValid && <p className="text-danger">Lütfen sadece PDF veya DOCX dosyası yükleyin!</p>}
            </Form.Group>

            <Button
              variant="primary"
              type="submit"
              disabled={!isFormValid || isSubmitting || timeLeft > 0}
              className="w-100"
            >
              {timeLeft > 0 ? `Lütfen ${timeLeft} saniye bekleyin...` : 'Gönder'}
            </Button>
          </Form>
        </Container>
      </section>
    </div>
  );
};

export default Career;

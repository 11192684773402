import './App.css';
import { BrowserRouter as Router, Routes, Route, Link, Navigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './Pages/Home';
import About from './Pages/About';
import Navbar from './components/CustomNavbar';
import Footer from './components/Footer';
import References from './Pages/References';
import Solutions from './Pages/Solutions';
import Blog from './Pages/Blog';
import BlogDetail from './Pages/BlogDetail';
import Sss from './Pages/Sss';
import Contact from './Pages/Contact';
import Career from './Pages/Career';
import FreeTrial from './Pages/FreeTrial';
import SolutionDetail from './Pages/SolutionDetail';
import Kvkk from './Pages/kvkk';
import Privacy from './Pages/privacy';
import Cookie from './Pages/cookie';
import CookieConsent from './components/CookieConsent';

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/references" element={<References />} />
          <Route path="/solutions" element={<Solutions />} />
          <Route path="/faq" element={<Sss />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:slug" element={<BlogDetail />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/career" element={<Career />} />
          <Route path="/free-trial" element={<FreeTrial />} />
          <Route path="/solutions/:slug" element={<SolutionDetail />} />
          <Route path="/kvkk" element={<Kvkk />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/cookie" element={<Cookie />} />

          {/* Geçersiz sayfalarda ana sayfaya yönlendirme */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
        <CookieConsent />
        <Footer />
      </div>
    </Router>
  );
}

export default App;

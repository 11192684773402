import React from 'react'
import ContactForm from '../components/ContactForm';
import Transformation from '../components/TransformationSection';
import PartnersSection from '../components/PartnersSection';
const References = () => {
  return (
    <div>

      <PartnersSection />
      <Transformation />
      <ContactForm />

    </div>
  )
}

export default References
import React from 'react'

const kvkk = () => {
  return (
    <div>
      <div className='kvkk container mt-5'>
      <h1>Kişisel Verilerin İşlenmesi Aydınlatma Metni</h1>
      <p className='text-center mb-5  mt-5'>
      KİŞİSEL VERİ SAKLAMA VE İMHA POLİTİKASI
1. Bu politikanın amacı, tamamen veya kısmen otomatik olan ya da herhangi bir veri kayıt sisteminin parçası olmak kaydıyla otomatik olmayan yollarla işlenen kişisel verilerin silinmesi, yok edilmesi veya anonim hale getirilmesine ilişkin usul ve esasları belirlemektir.

2. Bu politika; 6698 sayılı Kanunun 7 nci maddesinin üçüncü fıkrası ile 22 nci maddesinin birinci fıkrasının (e) bendine dayanılarak hazırlanmış Kişisel Verilerin Silinmesi, Yok Edilmesi Veya Anonim Hale Getirilmesi Yönetmeliğine uygun olarak hazırlanmıştır.

3. Tanımlar
3.1. Alıcı grubu: Veri sorumlusu tarafından kişisel verilerin aktarıldığı gerçek veya tüzel kişi kategorisidir.

3.2. İlgili kullanıcı: Verilerin teknik olarak depolanması, korunması ve yedeklenmesinden sorumlu olan kişi ya da birim hariç olmak üzere veri sorumlusu organizasyonu içerisinde veya veri sorumlusundan aldığı yetki ve talimat doğrultusunda kişisel verileri işleyen kişilerdir.

3.3. İmha: Kişisel verilerin silinmesi, yok edilmesi veya anonim hale getirilmesi işlemidir.

3.4. Kayıt ortamı: Tamamen veya kısmen otomatik olan ya da herhangi bir veri kayıt sisteminin parçası olmak kaydıyla otomatik olmayan yollarla işlenen kişisel verilerin bulunduğu her türlü ortamı ifade eder.

3.5. Kişisel veri işleme envanteri: Veri sorumlularının iş süreçlerine bağlı olarak gerçekleştirmekte oldukları kişisel verileri işleme faaliyetlerini; kişisel verileri işleme amaçları, veri kategorisi, aktarılan alıcı grubu ve veri konusu kişi grubuyla ilişkilendirerek oluşturdukları ve kişisel verilerin işlendikleri amaçlar için gerekli olan azami süreyi, yabancı ülkelere aktarımı öngörülen kişisel verileri ve veri güvenliğine ilişkin alınan tedbirleri açıklayarak detaylandırdıkları envanterdir.

3.6. Kişisel veri: Kimliği belirli veya belirlenebilir gerçek kişiye ilişkin her türlü bilgi

3.7. Kişisel veri saklama ve imha politikası: Veri sorumlularının, kişisel verilerin işlendikleri amaç için gerekli olan azami süreyi belirleme işlemi ile silme, yok etme ve anonim hale getirme işlemi için dayanak yaptıkları politikadır.

3.8. Periyodik imha: Kanunda yer alan kişisel verilerin işlenme şartlarının tamamının ortadan kalkması durumunda kişisel verileri saklama ve imha politikasında belirtilen ve tekrar eden aralıklarla resen gerçekleştirilecek silme, yok etme veya anonim hale getirme işlemini ifade eder.

3.9. Sicil: Kişisel Verileri Koruma Kurumu Başkanlığı tarafından tutulan veri sorumluları sicilini ifade eder.

3.10. Veri kayıt sistemi: Kişisel verilerin belirli kriterlere göre yapılandırılarak işlendiği kayıt sistemini ifade eder.

3.11. Veri sorumlusu: Kişisel verilerin işleme amaçlarını ve vasıtalarını belirleyen, veri kayıt sisteminin kurulmasından ve yönetilmesinden sorumlu olan gerçek veya tüzel kişiyi ifade eder.

3.12. Kişisel verilerin silinmesi: Kişisel verilerin silinmesi, kişisel verilerin ilgili kullanıcılar için hiçbir şekilde erişilemez ve tekrar kullanılamaz hale getirilmesi işlemidir.

3.13. Kişisel verilerin yok edilmesi: Kişisel verilerin yok edilmesi, kişisel verilerin hiç kimse tarafından hiçbir şekilde erişilemez, geri getirilemez ve tekrar kullanılamaz hale getirilmesi işlemidir.

3.14. Kişisel verilerin anonim hale getirilmesi: Kişisel verilerin başka verilerle eşleştirilse dahi hiçbir surette kimliği belirli veya belirlenebilir bir gerçek kişiyle ilişkilendirilemeyecek hale getirilmesidir. Kişisel verilerin anonim hale getirilmiş olması için; kişisel verilerin, veri sorumlusu, alıcı veya alıcı grupları tarafından geri döndürme ve verilerin başka verilerle eşleştirilmesi gibi kayıt ortamı ve ilgili faaliyet alanı açısından uygun tekniklerin kullanılması yoluyla dahi kimliği belirli veya belirlenebilir bir gerçek kişiyle ilişkilendirilemez hale getirilmesi gerekir.

4. Kişisel veri saklama ve imha politikası ile düzenlenen kayıt ortamları:
4.1. Kağıt ortamlar

4.2. Elektronik ortamlar

5. Kişisel verilerin saklanmasını ve imhasını gerektiren hukuki, teknik ya da diğer sebeplere ilişkin açıklamalar:
5.1. Kişisel verilerin işlenme şartlarının tamamının ortadan kalkması halinde, kişisel verilerin veri sorumlusu tarafından resen veya ilgili kişinin talebi üzerine silinmesi, yok edilmesi veya anonim hâle getirilmesi gerekir.

5.2. Türk Ceza Kanunu’nun 138. maddesinde ve KVK Kanunu’nun 7. maddesinde düzenlendiği üzere ilgili kanun hükümlerine uygun olarak işlenmiş olmasına rağmen, işlenmesini gerektiren sebeplerin ortadan kalkması halinde Şirket kendi kararına istinaden veya kişisel veri sahibinin talebi üzerine kişisel veriler silinir, yok edilir veya anonim hale getirilir.

5.3. 6493 sayılı Ödeme ve Menkul Kıymet Mutabakat Sistemleri, Ödeme Hizmetleri ve Elektronik Para Kuruluşları hakkında Kanunun 23. maddesi gereğince işletme konusuna giren iş ve işlemlerden kaynaklı belgeler ve kayıtlar en az on yıl süreyle güvenli ve Merkez Bankası tarafından istenildiği an erişime imkân sağlayacak şekilde yurt içinde saklanır.

5.4. İlgili kişi, Şirkete başvurarak kendisine ait kişisel verilerin silinmesini veya yok edilmesini talep ettiğinde bu talebi yerine getirilmek üzere hemen değerlendirmeye alınır.

5.5. Kişisel verileri işleme şartlarının tamamı ortadan kalkmışsa; Şirket talebe konu kişisel verileri siler, yok eder veya anonim hale getirir. Şirket, ilgili kişinin talebini en geç otuz gün içinde sonuçlandırır ve ilgili kişiye bilgi verir.

5.6. Kişisel verileri işleme şartlarının tamamı ortadan kalkmış ve talebe konu olan kişisel veriler üçüncü kişilere aktarılmışsa Şirket bu durumu üçüncü kişiye bildirir; üçüncü kişi nezdinde bu politika kapsamında gerekli işlemlerin yapılmasını temin eder.

5.7. Kişisel verileri işleme şartlarının tamamı ortadan kalkmamışsa, bu talep Şirket tarafından gerekçesi açıklanarak reddedilebilir ve ret cevabı ilgili kişiye en geç otuz gün içinde yazılı olarak ya da elektronik ortamda bildirilir.

5.8. Saklamayı gerektiren işleme amaçları;
5.8.1. Web sitesine üye olunması ile ilgili kişilerin web sitesi üzerindeki hizmetlerden faydalandırılması,

5.8.2. İlgili kişi tarafından iletişim sekmesinde yer alan kanallardan talepte bulunulması halinde, bilgi alınmasının sağlanması, dilek/önerilerin değerlendirmeye alınması ve şikayette bulunulabilmesinin sağlanması ve hukuki uyum, iç denetim, analiz ve sair diğer süreçlerin yürütülmesi,

5.8.3. Şirket tarafından sunulan hizmetlerin ilgili kişilerin beğeni, kullanım alışkanlıkları ve ihtiyaçlarına göre özelleştirilerek ilgili kişilere önerilmesi ve tanıtılması için gerekli olan aktivitelerin planlanması ve icrası,

5.8.4. Şirket tarafından yürütülen ticari faaliyetlerin gerçekleştirilmesi için ilgili iş birimlerimiz tarafından gerekli çalışmaların yapılması ve buna bağlı iş süreçlerinin yürütülmesi,

5.8.5. Şirket'in ticari ve/veya iş stratejilerinin planlanması ve icrası,

5.8.6. Şirket'in ve Şirket'le iş ilişkisi içerisinde Müşterilerimiz, tedarikçilerimiz, iş ortaklarımız, iş birliği içinde ya da ilişkide olduğumuz firma ve kurum çalışanları, ziyaretçilerimiz ve sair 3. kişiler ile iletişim ve ilişkimizin sağlanması ve bu kişilerin hukuki ve ticari güvenliğinin temini,

5.8.7. Çalışanlarımız, çalışan adaylarımız, Şirket yetkililerimiz ve Şirket yöneticilerimiz ile iletişim ve ilişkilerimizin sağlanması,

5.8.8. Tahsilat ve satın alma faaliyetleri ile muhasebe/mali işler işleyişinin devamının sağlanması,

5.8.9. Şirkete ait lokasyonların fiziksel güvenliğinin ve denetiminin sağlanması,

5.8.10. Şirketimizin kendini yenileme, iyileştirme, büyüme, yatırım çalışmalarının hukuka uygun sürdürülmesi,

5.8.11. Şirketimizin iç işleyişindeki finansal süreçlerin gereği gibi yürütülebilmesi,

5.8.12. Şirketimizin iç işleyişindeki insan kaynakları süreçlerinin gereği gibi yürütülebilmesi,

5.8.13. Ofis içindeki çalışma hayatının gereği gibi devam ettirilmesi,

5.8.14. Şirketimizin Bilgi Teknolojileri kapsamındaki zorunlu ve ihtiyari çalışmalarının devam ettirilmesi,

5.8.15. Kurumsal iletişimi sağlanması,

5.8.16. İstatiksel çalışmalar yapılabilmesi,

5.8.17. İmzalanan sözleşmeler ve protokoller neticesinde iş ve işlemleri ifa edilebilmesi,

5.8.18. Yasal düzenlemelerin gerektirdiği veya zorunlu kıldığı şekilde, hukuki yükümlülüklerin yerine getirilmesini sağlanması,

5.8.19. Kurum ile iş ilişkisinde bulunan gerçek/ tüzel kişilerle irtibat sağlanması,

5.8.20. İleride doğabilecek hukuki uyuşmazlıklarda delil olarak ispat yükümlülüğü,

5.8.21. Çağrı merkezi süreçlerini yönetilmesi

5.9. Koşullar
Şirket nezdinde Politika’nın 5.8 maddesinde sayılan amaçlarla işlenen kişisel veriler için KVKK’nın 5. maddesinin 1. fıkrası çerçevesinde veri sahibinin açık rızası alınmakta veya aynı maddenin 2. fıkrasında yer alan ve işbu Politika’nın 8.2. maddesinde açıklanan koşulların gerçekleşmiş olması aranmaktadır.

5.10. İmhayı gerektiren sebepler;
5.10.1. Kişisel verinin işlenmesine esas teşkil eden ilgili mevzuat hükümlerinin değiştirilmesi veya ilgası,

5.10.2. Kişisel verinin işlenmesini veya saklanmasını gerektiren amacın ortadan kalkması,

5.10.3. Kişisel veriyi işlemenin sadece açık rıza şartına istinaden gerçekleştiği hallerde, ilgili kişinin açık rızasını geri alması,

5.10.4. İlgili kişinin hakları çerçevesinde kişisel verilerinin silinmesi ve yok edilmesine ilişkin yaptığı başvurunun Şirket tarafından kabul edilmesi,

5.10.5. Şirketin, ilgili kişi tarafından kişisel verilerinin silinmesi, yok edilmesi veya anonim hale getirilmesi talebi ile kendisine yapılan başvuruyu reddetmesi, verdiği cevabı yetersiz bulması veya Kanunda öngörülen süre içinde cevap vermemesi hallerinde; Kişisel Verileri Koruma Kuruluna şikayette bulunması ve bu talebin Kurul tarafından uygun bulunması,

5.10.6. Kişisel verilerin saklanmasını gerektiren azami sürenin geçmiş olması ve kişisel verileri daha uzun süre saklamayı haklı kılacak herhangi bir şartın mevcut olmaması.

6. Kişisel Verilerin İşlenmesine İlişkin Prosedür
Şirket, Anayasa’nın 20. Maddesi ve KVKK’nın 5. Maddesinde yer alan prensiplere uygun bir şekilde veri sahiplerinin verilerini elde etmekte ve işlemektedir. Bunun yanında Şirket, veri sahiplerinden KVKK’nın 6. Maddesinde tanımlanan özel nitelikli veri statüsüne giren bir veri talebinde bulunmamaktadır. Şirketin veri işlemesi prosedürü esnasında uyduğu temel ilkeler aşağıda belirtilmiştir.

6.1. Kişisel Verilerin İşlenmesinde Temel İlkeler
6.1.1. Hukuka ve Dürüstlük Kuralına Uygun İşleme
Şirket; kişisel verilerin işlenmesinde hukuksal düzenlemelerle getirilen ilkeler ile genel güven ve dürüstlük kuralına uygun hareket etmektedir. Bu kapsamda Şirket, kişisel verilerin işlenmesinde orantılılık gerekliliklerini dikkate almakta, kişisel verileri amacın gerektirdiği dışında kullanmamaktadır.

6.2. Kişisel Verilerin Kanun’da Sayılı İstisnalar Kapsamında İşlenmesi
KVKK’nın 5. Maddesinin 1. fıkrasına göre kişisel verilerinin işlenmesi için temel şart olan veri sahibinin açık rızasının alınması kuralı ancak aynı maddenin 2. fıkrasında sayılan istisnaların uygulanması halinde zorunluluk arz etmemektedir. Veri sahibinin açık rızası dışında verinin işlenmesini hukuka uygun hale getiren diğer şartlara ilişkin açıklamalara aşağıda yer verilmiştir. Belirtmek gerekir bu şartlar kapsamında Şirket bünyesinde muhafaza edilen ve işlenen verilerin tamamı KVKK’nın 4. maddesinde yer verilen veri işlenmesinde uygulanması gereken temel ilkelere bağlı kalınarak işlenmektedir.

6.2.1. Kanun’da Açıkça Öngörülmesi
Veri sorumlusunun iştigal alanı dolayısıyla tabi olduğu mevzuat hükümlerince ilgili verilerin işlenmesi hüküm altına alındığı hallerde veri sahibinin açık rızası olmaksızın verilerin işlenmesi mümkündür.

6.2.2. Fiili İmkansızlık Sebebiyle Veri Sahibinin Açık Rızasının Alınamaması
Fiili imkansızlık nedeni ile açık rızası alınamayan veya rızasının hukuken geçerlilik arz etmeyeceği durumda olan kişilere ait kişisel verilerin ilgili kişinin veya 3. kişilerin beden bütünlüğünü korumak gibi üstün bir yararın bulunması halinde işlenmesi mümkündür.

6.2.3. Sözleşmenin Kurulması veya Sözleşmesel Yükümlülüklerin Yerine Getirilmesi
Sözleşmeden kaynaklanan yükümlülüklerin yerine getirilmesinin kişisel verinin işlenmesini zorunlu kılması halinde verilerin işlenmesi mümkündür.

6.2.4. Şirketin Hukuki Yükümlülüğünü Yerine Getirmesi
Şirketin hukuki yükümlülüklerini yerine getirebilmesi için ilgili verilerin işlenmesinin zorunlu olması hallerinde veri sahibinin kişisel verileri işlenebilecektir.

6.2.5. Veri Sahibinin Kişisel Verilerini Alenileştirmesi
Veri Sahibi tarafından kamuya açılan bilgiler Şirket tarafından işlenebilecektir.

6.2.6. Bir Hakkın Tesisi, Kullanımı ve Korunması için Veri İşlemenin Zorunlu Olması
Bir hakkın tesisi, kullanılması veya korunması için veri işlemenin zorunlu olması halinde veri sahibinin kişisel verileri işlenebilecektir.

6.2.7. Meşru Menfaatleri için Veri İşlemenin Zorunlu Olması
Veri Sahibinin temel hak ve özgürlüklerine zarar vermemek kaydı ile Şirket’in meşru menfaati gereğince ilgili verilerin işlenmesinin zorunlu olduğu durumlarda veri işlenmesi hukuka uygundur.

7. Kişisel verileri saklama ve imha süreçlerinde yer alanların unvanlarına, birimleri ve görev tanımları:
7.1. Bilgi İşlem Birimi Yöneticisi; Şirketin tüm Bilgi İşlem süreçlerini yönetir.

7.2. Hukuk Birimi Yöneticisi, Şirketin tüm hukuki işlem süreçlerini yönetir.

7.3. İnsan Kaynakları Yöneticisi (Personel ile ilgili konularda), Şirketin tüm personel süreçlerini yönetir.

7.4. Satış ve Pazarlama Yöneticisi (Müşteri bilgileri ile ilgili konularda); Şirketin tüm satış pazarlama süreçlerini yönetir.

8. . Kişisel Verilerin Silinmesi, Yok Edilmesi ve Anonimleştirilmesi
8.1. Şirket KVKK ve Kişisel Verilerin Silinmesi, Yok Edilmesi ve Anonim Hale Getirilmesi hakkında Yönetmelik’in ilgili hükümleri uyarınca işlemekte olduğu verileri, verilerin işlenmesini gerektiren koşulların ortadan kalkması halinde mevzuatta belirtilen usuller uygun şekilde verileri sileceğini, yok edeceğini veya anonim hale getireceğini kabul ve taahhüt eder. Bunun yanında Şirket, ilgili mevzuat hükümleri ve Türk Ceza Kanunu’nun 138. maddesi çerçevesinde kanunun belirlediği saklama ve işleme süreleri geçmiş kişisel verilerin de aynı usullerle silineceğini, yok edileceğini ve anonim hale getirileceğini kabul eder.

Bilgilerinize sunarız.

Saygılarımızla

HST BİLİŞİM TEKNOLOJİLERİ A.Ş.
      </p>
    </div>
    </div>
  )
}

export default kvkk
import React, { useEffect } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLaptopCode, faCloud, faLock, faMobileAlt } from '@fortawesome/free-solid-svg-icons';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles
import './ServicesSection.css'; // Optional: For custom styling
import { useTranslation } from 'react-i18next'; // i18n hook

const ServicesSection = () => {
  const { t } = useTranslation(); // useTranslation hook

  // Initialize AOS
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration
      easing: 'ease-in-out', // Smooth animation easing
      once: true, // Whether the animation should happen only once
    });
  }, []);

  return (
    <Container className="services-section py-5">
      <Row className="g-4">
        {/* Service 1 */}
        <Col md={3} data-aos="fade-up">
          <Card className="text-center p-4 service-card h-100 d-flex flex-column">
            <FontAwesomeIcon icon={faLaptopCode} size="3x" className="mb-3 text-success" />
            <Card.Body className="d-flex flex-column">
              <Card.Title>{t('services.software.title')}</Card.Title>
              <Card.Text className="flex-grow-1">
                {t('services.software.description')}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>

        {/* Service 2 */}
        <Col md={3} data-aos="fade-up" data-aos-delay="200">
          <Card className="text-center p-4 service-card h-100 d-flex flex-column">
            <FontAwesomeIcon icon={faCloud} size="3x" className="mb-3 text-info" />
            <Card.Body className="d-flex flex-column">
              <Card.Title>{t('services.cloud.title')}</Card.Title>
              <Card.Text className="flex-grow-1">
                {t('services.cloud.description')}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>

        {/* Service 3 */}
        <Col md={3} data-aos="fade-up" data-aos-delay="400">
          <Card className="text-center p-4 service-card h-100 d-flex flex-column">
            <FontAwesomeIcon icon={faLock} size="3x" className="mb-3 text-primary" />
            <Card.Body className="d-flex flex-column">
              <Card.Title>{t('services.security.title')}</Card.Title>
              <Card.Text className="flex-grow-1">
                {t('services.security.description')}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>

        {/* Service 4 */}
        <Col md={3} data-aos="fade-up" data-aos-delay="600">
          <Card className="text-center p-4 service-card h-100 d-flex flex-column">
            <FontAwesomeIcon icon={faMobileAlt} size="3x" className="mb-3 text-purple" />
            <Card.Body className="d-flex flex-column">
              <Card.Title>{t('services.mobile.title')}</Card.Title>
              <Card.Text className="flex-grow-1">
                {t('services.mobile.description')}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ServicesSection;

import React from 'react'
import Innovative from '../components/InnovativeSolutionsSection';
import DigitalSolutionsCustomSection from '../components/DigitalSolutionsSection';
import VideoGallery from '../components/VideoGallery';
const Solutions = () => {
  return (
    <div>
      <Innovative />
      <DigitalSolutionsCustomSection />
      <VideoGallery />
    </div>
  )
}

export default Solutions
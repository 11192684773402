// src/components/BlogSection.js

import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './BlogSection.css';
import blogData from '../data/blogData';
import Logo from '../assets/logos/bilisimsiyahlogo.png';
import { useTranslation } from 'react-i18next'; 

const BlogSection = () => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const currentLang = i18n.language;

  return (
    <section className="blog-section-custom my-5">
      <div className="container">
        {/* Ana başlık: H1 */}
        <div className="blog-header text-center mb-4" data-aos="fade-left">
          <img src={Logo} alt="HST Bilişim Logo" className="blog-logo" />
          <h1 className="blog-title">{t('blog.title')}</h1>
        </div>
        <p className="text-center mb-5" data-aos="fade-up">
          {t('blog.description')}
        </p>

        <div className="row">
          {blogData.map((post, index) => (
            <div className="col-md-4 mb-4" key={index} data-aos="fade-down" data-aos-delay={index * 100}>
              <div className="card h-100 blog-card-custom">
                {/* Blog Resmi */}
                <img src={post.image} className="card-img-top" alt={post.title[currentLang]} />
                <div className="blog-card-body-custom">
                  {/* Blog Kategorisi */}
                  <p className="blog-category-text-custom">{post.category[currentLang]}</p>
                  {/* Blog Başlığı: H2 */}
                  <h2 className="blog-card-title-custom">{post.title[currentLang]}</h2>
                  {/* Blog İçeriği */}
                  <p className="blog-card-text-custom">{post.content[currentLang]}</p>
                  {/* Blog Linki */}
                  <a href={`/blog/${post.slug}`} className="blog-read-more-link-custom">
                    {t('blog.readMore')}
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default BlogSection;

import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faXTwitter, faFacebook } from '@fortawesome/free-brands-svg-icons';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './Footer.css';
import logosiyah from '../assets/logos/bilisimsiyahlogo.png';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation();

  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: 'ease-in-out',
      once: true,
    });
  }, []);

  return (
    <footer className="footer">
      <Container>
        <Row>
          {/* Company Information */}
          <Col md={3} sm={6} xs={12} className="footer-contact" data-aos="fade-up">
            <a href="/">
              <img src={logosiyah} alt="HST Bilişim Logo" className="footer-logo" />
            </a>  
            <h5>{t('footer.supportContact')}</h5>
            <a href='tel:+90 212 706 5166'>
              <p>{t('footer.supportLine')}: +90 212 706 5166</p>
            </a>
            <a href='mailto:info@hstbilisim.com'>
              <p>{t('footer.emailAddress')}: info@hstbilisim.com</p>
            </a>
            <p>{t('footer.socialMedia')}</p>
            <div className="social-icons">
              <a href="https://www.instagram.com/hstbilisim/" aria-label="HST Bilişim Instagram">
                <FontAwesomeIcon icon={faInstagram} />
              </a>
              <a href="https://x.com/BilisimHst" aria-label="HST Bilişim Twitter">
                <FontAwesomeIcon icon={faXTwitter} />
              </a>
              <a href="https://www.facebook.com/hstbilisimm" aria-label="HST Bilişim Facebook">
                <FontAwesomeIcon icon={faFacebook} />
              </a>
            </div>
          </Col>

          {/* Corporate Links */}
          <Col md={3} sm={6} xs={12} data-aos="fade-up" data-aos-delay="100">
            <h5>{t('footer.corporate')}</h5>
            <ul className="footer-links">
              <li><a href="/about">{t('footer.about')}</a></li>
              <li><a href="/about">{t('footer.corporateIdentity')}</a></li>
              <li><a href="/career">{t('footer.career')}</a></li>
              <li><a href="/references">{t('footer.references')}</a></li>
              <li><a href="/solutions">{t('footer.solutions')}</a></li>
              <li><a href="/contact">{t('footer.contact')}</a></li>
            </ul>
          </Col>

          {/* Products & Services */}
          <Col md={3} sm={6} xs={12} data-aos="fade-up" data-aos-delay="300">
            <h5>{t('footer.productsServices')}</h5>
            <ul className="footer-links">
              <li><a href="/solutions/ozel-yazilim-gelistirme">{t('footer.customSoftware')}</a></li>
              <li><a href="/solutions/e-ticaret-uygulamasi">{t('footer.ecommerceApp')}</a></li>
              <li><a href="/solutions/demirbas-takip-uygulamasi">{t('footer.assetTracking')}</a></li>
              <li><a href="/solutions/ik-performans-yonetimi">{t('footer.performanceManagement')}</a></li>
              <li><a href="/solutions/hygiamed-saglik-asistani-uygulamasi">{t('footer.healthAssistantApp')}</a></li>
              <li><a href="/solutions/arac-takip-uygulamasi">{t('footer.vehicleTracking')}</a></li>
            </ul>
          </Col>

          {/* Customer Center */}
          <Col md={3} sm={6} xs={12} data-aos="fade-up" data-aos-delay="400">
            <h5>{t('footer.customerCenter')}</h5>
            <ul className="footer-links">
              <li><a href="/blog">{t('footer.blog')}</a></li>
              <li><a href="/faq">{t('footer.faq')}</a></li>
              <li><a href="/privacy">{t('footer.privacyCenter')}</a></li>
              <li><a href="/cookie">{t('footer.cookiePolicy')}</a></li>
              <li><a href="/kvkk">{t('footer.kvkk')}</a></li>
            </ul>
          </Col>
        </Row>

        <Row className="footer-bottom" data-aos="fade-up" data-aos-delay="500">
          <Col className="text-center">
            <p>© {new Date().getFullYear()} {t('footer.copyright')}</p>
            <p>{t('footer.developedBy')} <a href='https://as4digital.com' target='_blank' rel='noopener noreferrer' style={{textDecoration:'none' , color:'#333'}}>AS4 Dijital Reklam Ajansı</a></p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;

import React from 'react'

const privacy = () => {
  return (
    <div>
      <div className='container'>
        <h1 className='text-center'>Gizlilik Politikası</h1>
        <p className='text-center mt-5 mb-5'>
          Gizlilik Politikası<br/>
          Bu web sitesi (“Site”) Mecidiye Mah. Ambarlıdere Yolu Sk. No.15/5 Beşiktaş/İstanbul
          adresinde mukim Hst Bilişim Teknolojileri A.Ş. (“Hst Bilişim”, “Şirket” ya da “Biz” olarak
          anılacaktır) tarafından yönetilmektedir.<br/><br/>
          Herhangi birinin hizmetimizi, https://hstbilisim.com/ web sitesini kullanmaya karar vermesi
          durumunda kişisel bilgilerin toplanması, kullanılması ve ifşa edilmesine ilişkin politikalarımız
          hakkında web sitesi ziyaretçilerini bilgilendirmek için kullanılır.<br/><br/>
          Hizmetimizi kullanmayı seçerseniz, bu politikayla bağlantılı bilgilerin toplanmasını ve
          kullanılmasını kabul etmiş olursunuz. Topladığımız kişisel bilgiler, hizmeti sağlamak ve
          iyileştirmek için kullanılır. Bilgilerinizi bu Gizlilik Politikasında açıklananlar dışında kimseyle
          kullanmayacağız veya paylaşmayacağız.<br/><br/>
          Bilgi Toplama ve Kullanım<br/>
          Daha iyi bir deneyim için, Hizmetimizi kullanırken, adınız, telefon numaranız ve e-posta
          adresiniz dahil ancak bunlarla sınırlı olmamak üzere, bize kişisel olarak tanımlanabilir belirli
          bilgileri sağlamanızı isteyebiliriz. Topladığımız bilgiler sizinle iletişim kurmak veya
          kimliğinizi belirlemek için kullanılacaktır.<br/><br/>
          Günlük Verileri<br/>
          Hizmetimizi her ziyaret ettiğinizde, tarayıcınızın bize gönderdiği ve Günlük Verileri adı verilen
          bilgileri topladığımızı size bildirmek isteriz. Bu Günlük Verileri, bilgisayarınızın İnternet
          Protokolü (IP) adresi, tarayıcı sürümü, ziyaret ettiğiniz Hizmetimizin sayfaları, ziyaretinizin
          saati ve tarihi, bu sayfalarda geçirilen süre ve diğer istatistikler gibi bilgileri içerebilir.<br/><br/>
          Tanımlama<br/>
          Bilgileri Tanımlama bilgileri, genellikle anonim benzersiz tanımlayıcı olarak kullanılan az
          miktarda veri içeren dosyalardır. Bunlar, ziyaret ettiğiniz web sitesinden tarayıcınıza gönderilir
          ve bilgisayarınızın sabit diskinde saklanır.<br/><br/>
          Web sitemiz bu “çerezleri” bilgi toplamak ve hizmetimizi geliştirmek için kullanır. Bu
          tanımlama bilgilerini kabul etme veya reddetme ve bilgisayarınıza bir tanımlama bilgisinin ne
          zaman gönderildiğini bilme olanağına sahipsiniz. Çerezlerimizi reddetmeyi seçerseniz
          Hizmetimizin bazı bölümlerini kullanamayabilirsiniz.<br/><br/>
          Tarayıcı Tanımlama Bilgileri Kılavuzunu takip ederek web tarayıcınızda tanımlama bilgilerini
          nasıl yöneteceğinizi öğrenebilirsiniz.<br/><br/>
          Veri Koruma<br/>
          Tıbbi kayıtlarınız da dahil olmak üzere bilgilerinizi en sıkı gizlilik içinde tutacağız. Verilerinizi,
          kayıtlarınızı veya bilgilerinizi rızanız olmadan ifşa etmeyeceğiz ve bunları yalnızca
          işleminizden etkilenen kişilerle paylaşacağız.<br/><br/>
          Hizmet Sağlayıcılar<br/>
          Aşağıdaki nedenlerle üçüncü taraf şirketleri ve bireyleri istihdam edebiliriz:<br/>
          Hizmetimizi kolaylaştırmak için; Hizmeti bizim adımıza sağlamak için;<br/>
          Hizmetle ilgili hizmetleri gerçekleştirmek için; veya Hizmetimizin kullanımını analiz etmemize
          yardımcı olmak için.<br/><br/>
          Güvenlik<br/>
          Bize kişisel bilgilerinizi sağlama konusundaki güveninize değer veriyoruz, bu nedenle bunları
          korumak için ticari olarak kabul edilebilir yöntemler kullanmaya çalışıyoruz.<br/><br/>
          Çocukların Gizliliği<br/>
          Hizmetlerimiz 18 yaşın altındaki hiç kimseye hitap etmemektedir. 18 yaşın altındaki
          çocuklardan bilerek kişisel olarak tanımlanabilir bilgiler toplamıyoruz. 18 yaşından küçük bir
          çocuğun bize kişisel bilgiler verdiğini öğrenmemiz durumunda, bu bilgileri derhal
          sunucularımızdan sileriz. Bir ebeveyn veya vasi iseniz ve çocuğunuzun bize kişisel bilgiler
          verdiğini biliyorsanız, uygun işlemi yapabilmemiz için lütfen bizimle iletişime geçin.<br/><br/>
          Bu Gizlilik Politikasındaki Değişiklikler, Gizlilik Politikamızı zaman zaman
          güncelleyebiliriz. Bu nedenle, herhangi bir değişiklik için bu sayfaya devamlı olarak bakmanızı tavsiye ederiz. 
          Bu sayfada yeni Gizlilik Politikasını yayınlayarak herhangi bir değişiklik hakkında sizi bilgilendireceğiz. 
          Bu değişiklikler, bu sayfada yayınlandıktan hemen sonra yürürlüğe girer.<br/><br/> 
          Bize Ulaşın<br/> 
          Gizlilik politikamızla ilgili herhangi bir sorunuz veya öneriniz varsa, lütfen bizimle iletişime geçmekten çekinmeyin:
          <a href="mailto:info@hstbilisim.com">info@hstbilisim.com</a> </p> </div> </div> ) }

export default privacy

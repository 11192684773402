import React from 'react'
import AboutSection from '../components/AboutSection'
const About = () => {
  return (
    <div>
      <AboutSection />
    </div>
  )
}

export default About
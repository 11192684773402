import Yazilim from "../assets/pictures/yazilimgelistirme.webp";
import Ecommerce from "../assets/pictures/e-ticaret.webp";
import Demirbas from "../assets/pictures/demirbastakip.webp";
import Performans from "../assets/pictures/ikperformans.webp";
import saglik from "../assets/pictures/saglikapp.webp";
import AracTakip from "../assets/pictures/aractakip.webp";
const solutionsData = [
  {
    id: 1,
    title: {
      en: "Custom Software Development",
      tr: "Özel Yazılım Geliştirme",
      fr: "Développement de logiciels sur mesure",
      es: "Desarrollo de software a medida",
      ar: "تطوير البرمجيات المخصصة",
      de: "Individuelle Softwareentwicklung"
    },
    description: {
      en: "Custom software solutions tailored to the unique needs of each business optimize business processes and increase efficiency.",
      tr: "Her işletmenin kendine özgü ihtiyaçlarına göre şekillenen özel yazılım çözümleri, iş süreçlerini optimize eder ve verimliliği artırır.",
      fr: "Des solutions logicielles sur mesure adaptées aux besoins spécifiques de chaque entreprise optimisent les processus métier et augmentent l'efficacité.",
      es: "Las soluciones de software a medida adaptadas a las necesidades únicas de cada empresa optimizan los procesos comerciales y aumentan la eficiencia.",
      ar: "تعمل حلول البرمجيات المخصصة المصممة وفقًا لاحتياجات كل شركة على تحسين العمليات التجارية وزيادة الكفاءة.",
      de: "Maßgeschneiderte Softwarelösungen, die auf die individuellen Bedürfnisse jedes Unternehmens abgestimmt sind, optimieren Geschäftsprozesse und steigern die Effizienz."
    },
    details: {
      en: "At HST Bilişim, we develop software that fully meets the needs of our customers, providing flexibility, security, and sustainability.",
      tr: "HST Bilişim olarak müşterilerimizin ihtiyaçlarına tam olarak uyum sağlayan yazılımlar geliştirerek, esneklik, güvenlik ve sürdürülebilirlik sunuyoruz.",
      fr: "Chez HST Bilişim, nous développons des logiciels qui répondent pleinement aux besoins de nos clients, offrant flexibilité, sécurité et durabilité.",
      es: "En HST Bilişim, desarrollamos software que satisface plenamente las necesidades de nuestros clientes, brindando flexibilidad, seguridad y sostenibilidad.",
      ar: "في HST Bilişim، نقوم بتطوير البرمجيات التي تلبي احتياجات عملائنا بالكامل، مما يوفر المرونة والأمان والاستدامة.",
      de: "Bei HST Bilişim entwickeln wir Software, die die Bedürfnisse unserer Kunden vollständig erfüllt und dabei Flexibilität, Sicherheit und Nachhaltigkeit bietet."
    },
    img: Yazilim,
    slug: "ozel-yazilim-gelistirme"
  },
  {
    id: 2,
    title: {
      en: "E-commerce Application",
      tr: "E-ticaret Uygulaması",
      fr: "Application de commerce électronique",
      es: "Aplicación de comercio electrónico",
      ar: "تطبيق التجارة الإلكترونية",
      de: "E-Commerce-Anwendung"
    },
    description: {
      en: "To succeed in the e-commerce world, it's crucial to have a user-friendly, secure, and high-performing platform.",
      tr: "E-ticaret dünyasında başarılı olmak için, kullanıcı dostu, güvenli ve performansı yüksek bir platforma sahip olmak büyük önem taşıyor.",
      fr: "Pour réussir dans le monde du commerce électronique, il est crucial de disposer d'une plateforme conviviale, sécurisée et performante.",
      es: "Para tener éxito en el mundo del comercio electrónico, es crucial tener una plataforma fácil de usar, segura y de alto rendimiento.",
      ar: "لتحقيق النجاح في عالم التجارة الإلكترونية، من الضروري امتلاك منصة سهلة الاستخدام وآمنة وعالية الأداء.",
      de: "Um im E-Commerce erfolgreich zu sein, ist es entscheidend, eine benutzerfreundliche, sichere und leistungsstarke Plattform zu haben."
    },
    details: {
      en: "HST Bilişim professionally launches your online store, offering secure payment systems and a seamless shopping experience.",
      tr: "HST Bilişim, işletmenizin online mağazasını profesyonelce hayata geçirerek, güvenli ödeme sistemleri ve müşteri memnuniyeti odaklı çözümler sunar.",
      fr: "HST Bilişim lance professionnellement votre boutique en ligne, offrant des systèmes de paiement sécurisés et une expérience d'achat fluide.",
      es: "HST Bilişim lanza profesionalmente su tienda en línea, ofreciendo sistemas de pago seguros y una experiencia de compra sin problemas.",
      ar: "HST Bilişim تطلق متجرك الإلكتروني بشكل احترافي، وتقدم أنظمة دفع آمنة وتجربة تسوق سلسة.",
      de: "HST Bilişim startet Ihren Online-Shop professionell und bietet sichere Zahlungssysteme und ein nahtloses Einkaufserlebnis."
    },
    img: Ecommerce,
    slug: "e-ticaret-uygulamasi"
  },
  {
    id: 3,
    title: {
      en: "Asset Tracking Application",
      tr: "Demirbaş Takip Uygulaması",
      fr: "Application de Suivi des Actifs",
      es: "Aplicación de Seguimiento de Activos",
      ar: "تطبيق تتبع الأصول",
      de: "Inventarverfolgungsanwendung"
    },
    description: {
      en: "Asset tracking can be a complex process for large businesses.",
      tr: "Demirbaş takibi, büyük işletmeler için karmaşık bir süreç olabilir.",
      fr: "Le suivi des actifs peut être un processus complexe pour les grandes entreprises.",
      es: "El seguimiento de activos puede ser un proceso complejo para las grandes empresas.",
      ar: "يمكن أن يكون تتبع الأصول عملية معقدة للشركات الكبيرة.",
      de: "Die Nachverfolgung von Anlagegütern kann für große Unternehmen ein komplexer Prozess sein."
    },
    details: {
      en: "HST Bilişim's asset tracking application allows companies to monitor and manage all their assets in real time. This application helps prevent asset loss and makes maintenance and renewal processes more efficient.",
      tr: "HST Bilişim'in demirbaş takip uygulaması, şirketlerin tüm varlıklarını anlık olarak izlemelerini ve yönetmelerini sağlar. Bu uygulama sayesinde, varlıkların kaybolmasını önlerken, bakım ve yenileme süreçlerini de daha verimli hale getirirsiniz.",
      fr: "L'application de suivi des actifs de HST Bilişim permet aux entreprises de surveiller et de gérer tous leurs actifs en temps réel. Cette application aide à prévenir la perte d'actifs et rend les processus de maintenance et de renouvellement plus efficaces.",
      es: "La aplicación de seguimiento de activos de HST Bilişim permite a las empresas monitorear y gestionar todos sus activos en tiempo real. Esta aplicación ayuda a prevenir la pérdida de activos y hace que los procesos de mantenimiento y renovación sean más eficientes.",
      ar: "تطبيق تتبع الأصول من HST Bilişim يسمح للشركات بمراقبة وإدارة جميع أصولها في الوقت الفعلي. يساعد هذا التطبيق في منع فقدان الأصول ويجعل عمليات الصيانة والتجديد أكثر كفاءة.",
      de: "Die Inventarverfolgungsanwendung von HST Bilişim ermöglicht es Unternehmen, alle ihre Vermögenswerte in Echtzeit zu überwachen und zu verwalten. Diese Anwendung hilft, den Verlust von Vermögenswerten zu verhindern und macht Wartungs- und Erneuerungsprozesse effizienter."
    },
    img: Demirbas,
    slug: "demirbas-takip-uygulamasi"
  },
  {
    id: 4,
    title: {
      en: "HR Performance Application",
      tr: "İK Performans Uygulaması",
      fr: "Application de Performance RH",
      es: "Aplicación de Rendimiento de RRHH",
      ar: "تطبيق أداء الموارد البشرية",
      de: "HR-Leistungsanwendung"
    },
    description: {
      en: "Human resources performance management and evaluation software.",
      tr: "İnsan kaynakları performans yönetimi ve değerlendirme yazılımı.",
      fr: "Logiciel de gestion et d'évaluation des performances des ressources humaines.",
      es: "Software de gestión y evaluación del rendimiento de recursos humanos.",
      ar: "برمجية إدارة وتقييم أداء الموارد البشرية.",
      de: "Software zur Leistungsbewertung und -verwaltung im Personalwesen."
    },
    details: {
      en: "HST Bilişim’s HR performance application provides a digital solution to monitor and evaluate employee performance. Processes such as performance evaluations, feedback, and development plans can be easily tracked and optimized with this application.",
      tr: "HST Bilişim’in İK performans uygulaması, çalışan performansını izlemek ve değerlendirmek için dijital bir çözüm sunar. Performans değerlendirmeleri, geri bildirimler ve gelişim planları gibi süreçler, bu uygulama ile kolayca takip edilebilir ve optimize edilebilir hale gelir.",
      fr: "L'application de performance RH de HST Bilişim offre une solution numérique pour surveiller et évaluer la performance des employés. Des processus tels que les évaluations de performance, les retours et les plans de développement peuvent être facilement suivis et optimisés avec cette application.",
      es: "La aplicación de rendimiento de RRHH de HST Bilişim proporciona una solución digital para monitorear y evaluar el rendimiento de los empleados. Procesos como las evaluaciones de rendimiento, los comentarios y los planes de desarrollo pueden ser fácilmente rastreados y optimizados con esta aplicación.",
      ar: "تطبيق أداء الموارد البشرية من HST Bilişim يوفر حلاً رقميًا لمراقبة وتقييم أداء الموظفين. يمكن تتبع العمليات مثل تقييمات الأداء والتعليقات وخطط التطوير بسهولة وتحسينها باستخدام هذا التطبيق.",
      de: "Die HR-Leistungsanwendung von HST Bilişim bietet eine digitale Lösung zur Überwachung und Bewertung der Mitarbeiterleistung. Prozesse wie Leistungsbewertungen, Feedback und Entwicklungspläne können mit dieser Anwendung einfach verfolgt und optimiert werden."
    },
    img: Performans,
    slug: "ik-performans-yonetimi"
  },
  {
    id: 5,
    title: {
      en: "HygiaMed Health Assistant Application",
      tr: "HygiaMed Sağlık Asistanı Uygulaması",
      fr: "Application d'Assistant Santé HygiaMed",
      es: "Aplicación de Asistente de Salud HygiaMed",
      ar: "تطبيق مساعد الصحة HygiaMed",
      de: "HygiaMed Gesundheitsassistent-Anwendung"
    },
    description: {
      en: "A digital assistant application that provides great convenience to individuals in health tracking.",
      tr: "Sağlık takibi konusunda bireylere büyük kolaylık sağlayan bir dijital asistan uygulaması.",
      fr: "Une application d'assistant numérique qui offre une grande commodité aux individus dans le suivi de la santé.",
      es: "Una aplicación de asistente digital que brinda gran comodidad a las personas en el seguimiento de la salud.",
      ar: "تطبيق مساعد رقمي يوفر راحة كبيرة للأفراد في تتبع الصحة.",
      de: "Eine digitale Assistenten-Anwendung, die Benutzern im Gesundheits-Tracking große Bequemlichkeit bietet."
    },
    details: {
      en: "HST Bilişim's HygiaMed application helps users track their health status regularly, manage doctor appointments, and receive personalized health advice.",
      tr: "HST Bilişim'in geliştirdiği HygiaMed uygulaması, kullanıcıların sağlık durumlarını düzenli olarak takip etmelerine, doktor randevularını yönetmelerine ve kişiselleştirilmiş sağlık tavsiyeleri almalarına yardımcı olur.",
      fr: "L'application HygiaMed développée par HST Bilişim aide les utilisateurs à suivre régulièrement leur état de santé, à gérer leurs rendez-vous médicaux et à recevoir des conseils de santé personnalisés.",
      es: "La aplicación HygiaMed desarrollada por HST Bilişim ayuda a los usuarios a seguir su estado de salud regularmente, gestionar citas médicas y recibir asesoramiento de salud personalizado.",
      ar: "تطبيق HygiaMed الذي طورته HST Bilişim يساعد المستخدمين في تتبع حالتهم الصحية بانتظام، وإدارة مواعيد الطبيب، والحصول على نصائح صحية مخصصة.",
      de: "Die von HST Bilişim entwickelte HygiaMed-Anwendung hilft Benutzern, ihren Gesundheitszustand regelmäßig zu überwachen, Arzttermine zu verwalten und personalisierte Gesundheitsberatung zu erhalten."
    },
    img: saglik,
    slug: "hygiamed-saglik-asistani-uygulamasi"
  },
  {
    id: 6,
    title: {
      en: "Vehicle Tracking Application",
      tr: "Araç Takip Uygulaması",
      fr: "Application de Suivi des Véhicules",
      es: "Aplicación de Seguimiento de Vehículos",
      ar: "تطبيق تتبع المركبات",
      de: "Fahrzeugverfolgungsanwendung"
    },
    description: {
      en: "The vehicle tracking application provides critical information such as real-time location tracking, speed, and fuel consumption to manage your fleet more efficiently.",
      tr: "Filonuzu daha verimli yönetmek için araç takip uygulaması, gerçek zamanlı konum takibi, hız ve yakıt tüketimi gibi kritik bilgileri sunar.",
      fr: "L'application de suivi des véhicules fournit des informations critiques telles que le suivi de la localisation en temps réel, la vitesse et la consommation de carburant pour gérer votre flotte plus efficacement.",
      es: "La aplicación de seguimiento de vehículos proporciona información crítica como el seguimiento de ubicación en tiempo real, la velocidad y el consumo de combustible para gestionar su flota de manera más eficiente.",
      ar: "يوفر تطبيق تتبع المركبات معلومات حيوية مثل تتبع الموقع في الوقت الفعلي، السرعة واستهلاك الوقود لإدارة أسطولك بشكل أكثر كفاءة.",
      de: "Die Fahrzeugverfolgungsanwendung bietet kritische Informationen wie Echtzeit-Standortverfolgung, Geschwindigkeit und Kraftstoffverbrauch, um Ihre Flotte effizienter zu verwalten."
    },
    details: {
      en: "With HST Bilişim’s vehicle tracking application, you can use your company's vehicles more effectively, reduce operational costs, and enhance security. The vehicle tracking system allows you to achieve maximum efficiency in fleet management, making your business processes easier.",
      tr: "HST Bilişim’in araç takip uygulaması ile işletmenizin araçlarını daha etkin bir şekilde kullanabilir, operasyonel maliyetleri düşürebilir ve güvenliği artırabilirsiniz. Araç takip sistemi ile filo yönetiminde maksimum verimlilik elde ederek, iş süreçlerinizi kolaylaştırıyoruz.",
      fr: "Avec l'application de suivi des véhicules de HST Bilişim, vous pouvez utiliser les véhicules de votre entreprise plus efficacement, réduire les coûts opérationnels et améliorer la sécurité. Le système de suivi des véhicules permet d'atteindre une efficacité maximale dans la gestion de la flotte, simplifiant ainsi vos processus commerciaux.",
      es: "Con la aplicación de seguimiento de vehículos de HST Bilişim, puedes utilizar los vehículos de tu empresa de manera más eficiente, reducir los costos operativos y mejorar la seguridad. El sistema de seguimiento de vehículos te permite lograr la máxima eficiencia en la gestión de la flota, facilitando tus procesos comerciales.",
      ar: "مع تطبيق تتبع المركبات من HST Bilişim، يمكنك استخدام مركبات شركتك بشكل أكثر فعالية، تقليل التكاليف التشغيلية وتعزيز الأمان. يتيح لك نظام تتبع المركبات تحقيق أقصى قدر من الكفاءة في إدارة الأسطول، مما يسهل عملياتك التجارية.",
      de: "Mit der Fahrzeugverfolgungsanwendung von HST Bilişim können Sie die Fahrzeuge Ihres Unternehmens effizienter nutzen, Betriebskosten senken und die Sicherheit erhöhen. Das Fahrzeugverfolgungssystem ermöglicht es Ihnen, maximale Effizienz im Flottenmanagement zu erreichen und Ihre Geschäftsprozesse zu vereinfachen."
    },
    img: AracTakip,
    slug: "arac-takip-uygulamasi"
  }
];

export default solutionsData;

import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useTranslation } from 'react-i18next'; // i18n çeviri desteği
import './InnovativeSolutionsSection.css';
import Logo from '../assets/logos/bilisimsiyahlogo.png';

const InnovativeSolutionsSection = () => {
  const { t } = useTranslation(); // useTranslation hook'u ile çeviri

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    // SEO için section etiketi kullanımı
    <section className="innovative-solutions-section">
      <Container>
        <Row className="text-center">
          {/* Logo ve başlık için <header> etiketi kullanımı */}
          <header className="blog-header text-center mb-4" data-aos="fade-left">
            <img src={Logo} alt="HST BİLİŞİM Logo" className="blog-logo" />
            <h1 className="blog-title">{t('innovativeSolutions.solutionsTitle')}</h1>
          </header>
          
          {/* Alt başlık için <h2> etiketi */}
          <h2 className="section-subtitle" data-aos="fade-right" data-aos-delay="100">
            {t('innovativeSolutions.subtitle')}
          </h2>
        </Row>
      </Container>
    </section>
  );
};

export default InnovativeSolutionsSection;

import React from 'react'
import ContactForm from '../components/ContactForm'
import OfficeSection from '../components/OfficeSection'
import VideoGallery from '../components/VideoGallery'
const Contact = () => {
  return (
    <div>
      <OfficeSection />
      <ContactForm />
      <VideoGallery />
    </div>
  )
}

export default Contact
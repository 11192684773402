import React from 'react'
import ContactForm from '../components/ContactForm'
import Logo from '../assets/logos/bilisimsiyahlogo.png';
import VideoGallery from '../components/VideoGallery';

const FreeTrial = () => {
  return (
    <div>
        <div className="blog-header text-center mb-4 mt-5" data-aos="fade-left">
          <img src={Logo} alt="HST BİLİŞİM Logo" className="blog-logo" />
          <h1 className="blog-title">Ücretsiz Deneme</h1>
        </div>
      <ContactForm />
      <VideoGallery />
    </div>
  )
}

export default FreeTrial
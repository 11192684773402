// src/components/SssSection.js

import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import './SssSection.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Logo from '../assets/logos/bilisimsiyahlogo.png';
import { useTranslation } from 'react-i18next';

const SssSection = () => {
  const [activeKey, setActiveKey] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const toggleActiveKey = (key) => {
    if (activeKey === key) {
      setActiveKey(null); 
    } else {
      setActiveKey(key);
    }
  };

  return (
    <Container className="custom-faq-section my-5" data-aos="fade-up">
      <div className="blog-header text-center mb-4" data-aos="fade-left">
        <img src={Logo} alt="HST BİLİŞİM Logo" className="blog-logo" />
        <h1 className="blog-title">{t('faq.title')}</h1>
      </div>      
      
      <div className="faq-list">
        <div
          className={`faq-item ${activeKey === "0" ? 'active' : ''}`} 
          onClick={() => toggleActiveKey("0")}
        >
          <p className="faq-question">{t('faq.q1.question')}</p>
          <div className={`faq-answer ${activeKey === "0" ? 'open' : ''}`}>
            {t('faq.q1.answer')}
          </div>
        </div>

        <div
          className={`faq-item ${activeKey === "1" ? 'active' : ''}`} 
          onClick={() => toggleActiveKey("1")}
        >
          <p className="faq-question">{t('faq.q2.question')}</p>
          <div className={`faq-answer ${activeKey === "1" ? 'open' : ''}`}>
            {t('faq.q2.answer')}
          </div>
        </div>

        <div
          className={`faq-item ${activeKey === "2" ? 'active' : ''}`} 
          onClick={() => toggleActiveKey("2")}
        >
          <p className="faq-question">{t('faq.q3.question')}</p>
          <div className={`faq-answer ${activeKey === "2" ? 'open' : ''}`}>
            {t('faq.q3.answer')}
          </div>
        </div>

        <div
          className={`faq-item ${activeKey === "3" ? 'active' : ''}`} 
          onClick={() => toggleActiveKey("3")}
        >
          <p className="faq-question">{t('faq.q4.question')}</p>
          <div className={`faq-answer ${activeKey === "3" ? 'open' : ''}`}>
            {t('faq.q4.answer')}
          </div>
        </div>

        <div
          className={`faq-item ${activeKey === "4" ? 'active' : ''}`} 
          onClick={() => toggleActiveKey("4")}
        >
          <p className="faq-question">{t('faq.q5.question')}</p>
          <div className={`faq-answer ${activeKey === "4" ? 'open' : ''}`}>
            {t('faq.q5.answer')}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default SssSection;

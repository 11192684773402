import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'; // AOS kütüphanesi stil dosyası
import { useTranslation } from 'react-i18next'; // i18n için çeviri kancası
import './TransformationSection.css'; // Özel stil dosyası

const TransformationSection = () => {
  // AOS'u başlatıyoruz
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animasyon süresi
      easing: 'ease-in-out', // Yumuşak animasyon çıkışı
      once: true, // Animasyonun sadece bir kez olması için
    });
  }, []);

  const { t } = useTranslation(); // Çeviri kancası

  return (
    <div className="transformation-section">
      {/* Ana içerik bölümü */}
      <div className="content" data-aos="fade-up">
        <p className="small-text" data-aos="fade-right" data-aos-delay="100">
          {t('transformation.smallText')}
        </p>
        <h2 className="large-text" data-aos="fade-left" data-aos-delay="200">
          {t('transformation.largeText')}
        </h2>
      </div>
    </div>
  );
};

export default TransformationSection;

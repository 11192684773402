// src/pages/BlogDetail.js

import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next'; // Translation hook import
import AOS from 'aos';
import 'aos/dist/aos.css'; 
import blogData from '../data/blogData';

const BlogDetail = () => {
  const { slug } = useParams();
  const { t, i18n } = useTranslation(); // Translation hook

  const blog = blogData.find((post) => post.slug === slug);

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  if (!blog) {
    return <h1>{t('blog.notFound')}</h1>;
  }

  // Get the current language (e.g., 'tr', 'en', 'fr')
  const currentLang = i18n.language;

  return (
    <div className="container my-5">
      <div className="row">
        <div className="col-md-8 offset-md-2">
          <img
            src={blog.image}
            alt={blog.title[currentLang]} // Title based on current language
            className="img-fluid mb-4"
            data-aos="fade-up"
          />
          <h1 data-aos="fade-right">{blog.title[currentLang]}</h1>
          <p data-aos="fade-left">
            <strong>{blog.category[currentLang]}</strong> | {new Date(blog.date).toLocaleDateString()}
          </p>

          {/* Blog content detail section */}
          {blog.detail[currentLang].map((paragraph, index) => (
            <p className="mb-4" key={index} data-aos="fade-up">{paragraph}</p>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BlogDetail;

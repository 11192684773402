import React, { useEffect } from 'react';
import { Navbar, Nav, Button, Container, Row, Col } from 'react-bootstrap';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './CustomNavbar.css';
import logosiyah from '../assets/logos/bilisimsiyahlogo.png';
import Flag from 'react-world-flags';
import { useTranslation } from 'react-i18next';

const CustomNavbar = () => {
  const { t, i18n } = useTranslation(); // useTranslation hook

  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: 'ease-in-out',
      once: true,
    });
  }, []);

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  return (
    <>
      {/* Top header for language flags */}
      <div className="top-header bg-light py-2">
        <Container>
          <Row className="justify-content-end">
            <Col xs="auto">
              <Flag code="TR" height="20" className="me-2 cursor-pointer" onClick={() => changeLanguage('tr')} />
              <Flag code="GB" height="20" className="me-2 cursor-pointer" onClick={() => changeLanguage('en')} />
              <Flag code="SA" height="20" className="me-2 cursor-pointer" onClick={() => changeLanguage('ar')} />
              <Flag code="FR" height="20" className="me-2 cursor-pointer" onClick={() => changeLanguage('fr')} />
              <Flag code="DE" height="20" className="me-2 cursor-pointer" onClick={() => changeLanguage('de')} />
              <Flag code="ES" height="20" className="me-2 cursor-pointer" onClick={() => changeLanguage('es')} />
            </Col>
          </Row>
        </Container>
      </div>

      {/* Main navbar */}
      <Navbar expand="lg" className="py-3" bg="white" variant="light" data-aos="fade-down">
        <Container>
          <Navbar.Brand href="/" data-aos="zoom-in">
            <img
              src={logosiyah}
              alt="Logo"
              width="120"
              height="auto"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="/solutions" data-aos="fade-right">{t('navbar.solutions')}</Nav.Link>
              <Nav.Link href="/About" data-aos="fade-right">{t('navbar.about')}</Nav.Link>
              <Nav.Link href="/references" data-aos="fade-right">{t('navbar.references')}</Nav.Link>
              <Nav.Link href="/blog" data-aos="fade-right">{t('navbar.blog')}</Nav.Link>
              <Nav.Link href="/faq" data-aos="fade-right">{t('navbar.faq')}</Nav.Link>
              <Nav.Link href="/contact" data-aos="fade-right">{t('navbar.contact')}</Nav.Link>
              <Nav.Link href="/career" data-aos="fade-right">{t('navbar.career')}</Nav.Link>
            </Nav>

            <a href="/free-trial" target="_blank" rel="noopener noreferrer" className="btn me-3">
              <Button variant="dark" className="me-3 trial-button">{t('navbar.freeTrial')} →</Button>
            </a>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default CustomNavbar;

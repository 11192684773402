import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Container, Row, Col } from 'react-bootstrap';
import './AboutSection.css'; 
import hstbilisim from '../assets/pictures/hakkimizda.webp';
import mission from "../assets/pictures/mission.webp";
import vision from "../assets/pictures/vision.webp";
import { useTranslation } from 'react-i18next';

const AboutSection = () => {
  const { t } = useTranslation();

  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: 'ease-in-out',
      once: true,
    });
  }, []);

  return (
    <Container className="about-section">
      {/* First Section */}
      <Row className="timeline">
        <Col md={4} className="timeline-text-col" data-aos="fade-right">
          <div className="timeline-text">
            <h2>{t('about.hstTitle')}</h2> {/* Ana başlık */}
            <p>{t('about.hstText')}</p>
          </div>
        </Col>
        <Col md={2} className="timeline-center-col">
          <div className="timeline-center">
            <span className="dot"></span>
          </div>
        </Col>
        <Col md={6} className="timeline-img-col" data-aos="fade-left">
          <img 
            src={hstbilisim} 
            alt={t('about.hstTitle')} 
            className="timeline-img"
            loading="lazy" 
          />
        </Col>
      </Row>

      {/* Second Section */}
      <Row className="timeline">
        <Col md={6} className="timeline-img-col" data-aos="fade-right">
          <img 
            src={mission} 
            alt={t('about.missionTitle')} 
            className="timeline-img"
            loading="lazy" 
          />
        </Col>
        <Col md={2} className="timeline-center-col">
          <div className="timeline-center">
            <span className="dot"></span>
          </div>
        </Col>
        <Col md={4} className="timeline-text-col" data-aos="fade-left">
          <div className="timeline-text">
            <h3>{t('about.missionTitle')}</h3> {/* Alt başlık */}
            <p>{t('about.missionText')}</p>
          </div>
        </Col>
      </Row>

      {/* Third Section */}
      <Row className="timeline">
        <Col md={4} className="timeline-text-col" data-aos="fade-right">
          <div className="timeline-text">
            <h3>{t('about.visionTitle')}</h3> {/* Alt başlık */}
            <p>{t('about.visionText')}</p>
          </div>
        </Col>
        <Col md={2} className="timeline-center-col">
          <div className="timeline-center">
            <span className="dot"></span>
          </div>
        </Col>
        <Col md={6} className="timeline-img-col" data-aos="fade-left">
          <img 
            src={vision} 
            alt={t('about.visionTitle')} 
            className="timeline-img"
            loading="lazy" 
          />
        </Col>
      </Row>
    </Container>
  );
};

export default AboutSection;

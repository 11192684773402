import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Card } from 'react-bootstrap';
import AOS from 'aos';
import 'aos/dist/aos.css';
import solutionsData from '../data/solutionsData'; // Data dosyasını import ediyoruz
import './SolutionsSection.css';
import { useTranslation } from 'react-i18next'; // i18n hook

const SolutionsSection = () => {
  const { t, i18n } = useTranslation(); // useTranslation hook'u
  const [showMore, setShowMore] = useState(false);

  // Initialize AOS
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: 'ease-in-out',
      once: true,
    });
  }, []);

  // Kartları iki grup olarak ayırıyoruz (İlk 6 ve geri kalanı)
  const firstSet = solutionsData.slice(0, 6);
  const currentLang = i18n.language || 'tr'; // Varsayılan dil Türkçe olsun

  return (
    <section className="solutions-section py-5">
      <Container>
        {/* Ana başlık için <h1> kullanımı */}
        <h1 className="text-center mb-4" data-aos="fade-up">{t('solutions.title')}</h1>
        <Row className="g-4">
          {/* İlk 6 kart her zaman görünür */}
          {firstSet.map((card, index) => (
            <Col md={4} key={card.id} data-aos="fade-up" data-aos-delay={index * 100}>
              {/* Her kartı bir article etiketi ile sarmalıyoruz */}
              <article>
                <Card className="h-100">
                  <Card.Img 
                    variant="top" 
                    src={card.img} 
                    alt={card.title[currentLang]}  // Görseller için alt etiketi
                  />
                  <Card.Body>
                    <Card.Title as="h2">{card.title[currentLang]}</Card.Title> {/* Alt başlık olarak h2 kullanıyoruz */}
                    <Card.Text>{card.description[currentLang]}</Card.Text>
                    <a href={`/solutions/${card.slug}`}>
                      <Button variant="link" className="extra-card-button">
                        {t('solutions.detailsButton')} →
                      </Button>
                    </a>
                  </Card.Body>
                </Card>
              </article>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default SolutionsSection;

import React from 'react';
import HeroSection from '../components/HeroSection';
import ServicesSection from '../components/ServicesSection';
import SolutionsSection from '../components/SolutionsSection';
import VideoGallery from '../components/VideoGallery';
import TransformationSection from '../components/TransformationSection';
import ContactForm from '../components/ContactForm';
const Home = () => {
  return (
    <div>
      <HeroSection />
      <ServicesSection />
      <SolutionsSection />
      <VideoGallery />
      <TransformationSection />
      <ContactForm />
    </div>
  )
}

export default Home
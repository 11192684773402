import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './OfficeSection.css';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next'; // i18next import

const OfficeSection = () => {
  const { t } = useTranslation(); // useTranslation hook

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <section className="office-section">
      <Container>
        <div data-aos="fade-up">
          <iframe 
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3008.5739329986723!2d29.0224577!3d41.056445599999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cab72e0ef96fc7%3A0xe99f2de5b6f55886!2sHST%20Bili%C5%9Fim%20Teknolojileri!5e0!3m2!1str!2str!4v1727943333649!5m2!1str!2str"
            width="100%" 
            height="450" 
            frameBorder="0" 
            allowFullScreen 
            className='rounded'
            title={t('office.googleMapTitle')} // Çeviri için kullanıldı
          />
        </div>

        <div className="office-content" data-aos="fade-right" data-aos-delay="200">
          <h1 className="office-title">{t('office.title')}</h1> {/* Çeviri */}
          <p className="office-description">
            {t('office.description')} {/* Çeviri */}
          </p>
          <h2 className="office-location">{t('office.istanbul')}</h2> {/* Çeviri */}
          <p className="office-location-subtitle"><strong>{t('office.besiktas')}</strong></p> {/* Çeviri */}
          <p className="office-address">
            {t('office.adddress.besiktas')}
            <br />
            <a href='tel:+90 212 706 5166' style={{ textDecoration: 'none', color: '#333' }}>0 212 706 5166</a>
          </p>
          <h2 className="office-location">{t('office.rndOffice')}</h2> {/* Çeviri */}
          <p className="office-location-subtitle"><strong>{t('office.umraniye')}</strong></p> {/* Çeviri */}
          <p className="office-address">
            {t('office.address.umraniye')}
            <br />
            <a href='tel:+90 212 706 5166' style={{ textDecoration: 'none', color: '#333' }}>0 212 706 5166</a>
          </p>
          <div className="office-map" data-aos="fade-left" data-aos-delay="400">
            <a 
              target="_blank" 
              href="https://www.google.com/maps/place/HST+Bilişim+Teknolojileri/@41.0564456,29.0198774,17z/data=!3m1!4b1!4m6!3m5!1s0x14cab72e0ef96fc7:0xe99f2de5b6f55886!8m2!3d41.0564456!4d29.0224577!16s%2Fg%2F11w2jph1lt?entry=ttu&g_ep=EgoyMDI0MDkzMC4wIKXMDSoASAFQAw%3D%3D"
              className="office-map-link"
              rel="noopener noreferrer"
            >
              {t('office.getDirections')} {/* Çeviri */}
            </a>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default OfficeSection;

import React from 'react'

const cookie = () => {
  return (
    <div>
      <div className='container'>
        <h1 className='text-center'>Çerez Politikası</h1>
        <p className='text-center mt-5 mb-5'>
          ÇEREZ (COOKIE) POLİTİKAMIZ<br/>
          Hst Bilişim Teknolojileri A.Ş. tarafından hazırlanan Çerez Politikası (aşağıda kısaca
          “Politika” olarak anılacaktır), www.hstbilisim.com web sitemizde kullanılan çerez türleri,
          çerezlerin kullanım amaçları ve bu çerezlerin ayarları, yönetilmesi ve silinmesi hakkında
          bilgilendirmek isteriz.<br/><br/>
          Çerez (Cookie) Nedir?<br/>
          Günümüzde neredeyse her web sitesi çerez kullanmaktadır. Size daha iyi, hızlı ve güvenli bir
          deneyim sağlamak için, çoğu internet sitesi gibi biz de çerezler kullanıyoruz. Çerez, bir web
          sitesini ziyaret ettiğinizde cihazınıza (örneğin; bilgisayar veya cep telefonu) depolanan küçük
          bir metin dosyasıdır. Çerezler, bir web sitesini ilk ziyaretiniz sırasında tarayıcınız aracılığıyla
          cihazınıza depolanabilirler. Aynı siteyi aynı cihazla tekrar ziyaret ettiğinizde tarayıcınız
          cihazınızda site adına kayıtlı bir çerez olup olmadığını kontrol eder. Eğer kayıt var ise, kaydın
          içindeki veriyi ziyaret etmekte olduğunuz web sitesine iletir. Bu sayede web sitesi, sizin siteyi
          daha önce ziyaret ettiğinizi anlar ve size iletilecek içeriği de ona göre tayin eder.<br/><br/>
          Çerezler Neden Kullanılır?<br/>
          Bazı çerezler, daha önceki ziyaretlerinizde kullandığınız tercihlerin web sitesi tarafından
          hatırlanmasını sağlayarak, sonraki ziyaretlerinizin çok daha kullanıcı dostu ve
          kişiselleştirilmiş bir deneyim sunmasını sağlar.<br/>
          Ayrıca, web sitesinde bulunan üçüncü taraflara ait linkler, bu üçüncü taraflara ait gizlilik
          politikalarına tabi olmakla birlikte, gizlilik uygulamalarına ait sorumluluk
          www.hstbilisim.com ’a ait olmamaktadır ve bu bağlamda ilgili link kapsamındaki site ziyaret
          edildiğinde siteye ait gizlilik politikasının okunması önerilmektedir.<br/><br/>
          Çerez Türleri<br/>
          Ana kullanım amacı kullanıcılara kolaylık sağlamak olan çerezler, temel olarak 4 ana grupta
          toplanmaktadır:<br/>
          • Oturum Çerezleri: Internet sayfaları arasında bilgi taşınması ve kullanıcı tarafından
          girilen bilgilerin sistemsel olarak hatırlanması gibi çeşitli özelliklerden faydalanmaya
          olanak sağlayan çerezlerdir ve internet sitesine ait fonksiyonların düzgün bir şekilde
          işleyebilmesi için gereklidir.<br/>
          • Performans Çerezleri: Sayfaların ziyaret edilme frekansı, olası hata iletileri,
          kullanıcıların ilgili sayfada harcadıkları toplam zaman ile birlikte siteyi kullanım
          desenleri konularında bilgi toplayan çerezlerdir ve internet sitesinin performansını
          arttırma amacıyla kullanılmaktadır.<br/>
          • Fonksiyonel Çerezler: Kullanıcıya kolaylık sağlanması amacıyla önceden seçili olan
          seçeneklerin hatırlatılmasını sağlayan çerezlerdir ve internet sitesi kapsamında
          kullanıcılara gelişmiş Internet özellikleri sağlanmasını hedeflemektedir.<br/>
          • Reklam Ve Üçüncü Taraf Çerezleri: Üçüncü parti tedarikçilere ait çerezlerdir ve
          internet sitesindeki bazı fonksiyonların kullanımına ve reklam takibinin yapılmasına
          olanak sağlamaktadır.<br/><br/>
          Çerezlerin Kullanım Amaçları<br/>
          www.hstbilisim.com tarafından kullanılmakta olan çerezlere ait kullanım amaçları aşağıdaki
          gibidir:<br/>
          • Güvenlik amaçlı kullanımlar: www.hstbilisim.com , sistemlerinin idaresi ve
          güvenliğinin sağlanması amacıyla, bu sitedeki fonksiyonlardan yararlanmayı
          sağlayan veyahut düzensiz davranışları tespit eden çerezler kullanabilmektedir.<br/>
          • İşlevselliğe yönelik kullanımlar: www.hstbilisim.com , sistemlerinin kullanımını
          kolaylaştırmak ve kullanıcı özelinde kullanım özellikleri sağlamak amacıyla,
          kullanıcıların bilgilerini ve geçmiş seçimlerini hatırlatan çerezler kullanabilmektedir.<br/>
          • Performansa yönelik kullanımlar: www.hstbilisim.com , sistemlerinin
          performansının artırılması ve ölçülmesi amacıyla, gönderilen iletilerle olan etkileşimi
          ve kullanıcı davranışlarını değerlendiren ve analiz eden çerezler kullanabilmektedir.<br/>
          • Reklam amaçlı kullanımlar: www.hstbilisim.com , kendine veya üçüncü taraflara
          ait sistemlerin üzerinden kullanıcıların ilgi alanları kapsamında reklam ve benzeri
          içeriklerin iletilmesi amacıyla, bu reklamların etkinliğini ölçen veya tıklanma
          durumunu analiz eden çerezler kullanabilmektedir.<br/><br/>
          Çerezleri Kontrol Etme ve Silme<br/>
          Çerezlerin kullanımına ilişkin tercihlerinizi değiştirmek ya da çerezleri engellemek veya
          silmek için tarayıcınızın ayarlarını değiştirmeniz yeterlidir. Birçok tarayıcı çerezleri kontrol
          edebilmeniz için size çerezleri kabul etme veya reddetme, yalnızca belirli türdeki çerezleri
          kabul etme ya da bir web sitesi cihazınıza çerez depolamayı talep ettiğinde tarayıcı tarafından
          uyarılma seçeneği sunar. Aynı zamanda daha önce tarayıcınıza kaydedilmiş çerezlerin
          silinmesi de mümkündür. Çerezleri kontrol edilmesine veya silinmesine ilişkin işlemler
          kullandığınız tarayıcıya göre değişebilmektedir. Bazı popüler tarayıcıların çerezlere izin
          verme ya da çerezleri engelleme veya silme talimatlarına aşağıdaki linklerden ulaşılması
          mümkündür. Mobil cihazlar üzerinden Çerezleri yönetmek için mobil cihaza ait ayarlar
          menüsü kullanılabilir.<br/>
          Çerez kullanım seçiminin değiştirilmesine ait yöntem, tarayıcı tipine bağlı olarak değişmekte
          olup, ilgili hizmet sağlayıcıdan dilendiği zaman öğrenilebilmektedir.<br/><br/>
          Rıza, Politikadaki Değişiklik ve Yürürlük<br/>
          Şirketimiz bu Politika ile ziyaretçilerine Çerez kullanımı konusunda detaylı açıklama
          sunmayı ve Çerez tercihleri konusunda bilgilendirmede bulunmayı hedeflemiştir. Bu
          bakımdan, Portalda yer alan Çerez bilgilendirme uyarısının kapatılması ve/veya Portalın
          kullanmaya devam edilmesi halinde Çerez kullanımına rıza verildiği kabul edilmektedir.
          Ziyaretçilerimiz Çerez tercihlerini her zaman değiştirebilirler.<br/>
          Şirketimiz bu Politika hükümlerini dilediği zaman değiştirme hakkını saklı tutar. Bu Politika
          yayınlandığı tarihte yürürlük kazanır.
        </p>
      </div>
    </div>
  )
}

export default cookie

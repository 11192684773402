import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next'; // i18n hook import edildi
import solutionsData from '../data/solutionsData';

const SolutionDetail = () => {
  const { slug } = useParams();
  const { i18n } = useTranslation(); // Dil bilgisi için i18n kullanılıyor

  const solution = solutionsData.find((s) => s.slug === slug);

  if (!solution) {
    return <h1>Çözüm Bulunamadı!</h1>;
  }

  // Mevcut dile göre title ve details alanlarını alıyoruz
  const currentLang = i18n.language || 'tr'; // Varsayılan dil 'tr' olsun
  const title = solution.title[currentLang];
  const details = solution.details[currentLang];

  return (
    <div className="container my-5">
      <div className="row">
        <div className="col-md-8 offset-md-2">
          <img src={solution.img} alt={title} className="img-fluid mb-4" />
          <h1>{title}</h1>
          <p>{details}</p>
        </div>
      </div>
    </div>
  );
};

export default SolutionDetail;

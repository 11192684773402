import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import './DigitalSolutionsSection.css'; 
import solutionsData from '../data/solutionsData'; 
import { useTranslation } from 'react-i18next';

const DigitalSolutionsCustomSection = () => {
  const { t, i18n } = useTranslation(); 
  const currentLang = i18n.language || 'tr'; 

  return (
    <div className="digital-solution-bg">
      <Container className="custom-digital-solutions-section py-5">
        <h1 className="custom-digital-title text-center mb-4" data-aos="fade-up">
          {t('digitalSolutions.title')} {/* H1 etiketi ana başlık */}
        </h1>
        <Row className="g-4">
          {solutionsData.map((solution, index) => (
            <Col md={4} key={index} data-aos="fade-up" data-aos-delay={index * 100}>
              <Card className="custom-digital-card h-100">
                <Card.Img 
                  variant="top" 
                  src={solution.img} 
                  alt={solution.title[currentLang]} 
                />
                <Card.Body>
                  <Card.Title className="custom-digital-card-title">
                    {solution.title[currentLang]}
                  </Card.Title>
                  <Card.Text className="custom-digital-card-text">
                    {solution.description[currentLang]}
                  </Card.Text>
                  <a href={`/solutions/${solution.slug}`}>
                    <Button variant="link" className='extra-card-button'>
                      {t('digitalSolutions.detailButton')} →
                    </Button>
                  </a>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default DigitalSolutionsCustomSection;

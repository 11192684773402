import React, { useState, useRef } from 'react';
import './VideoGallery.css';
import Video1 from '../assets/videos/1.mp4';
import Video2 from '../assets/videos/2.mp4';
import Video3 from '../assets/videos/3.mp4';
import aracTakip from '../assets/videos/aractakip.jpg';
import hygiaMed from '../assets/videos/hygiamed.jpg';
import performans from '../assets/videos/ik.jpg';
import { useTranslation } from 'react-i18next'; // Import useTranslation for i18n

const VideoGallery = () => {
  const [clickedVideo, setClickedVideo] = useState(null);
  const videoRefs = useRef([]); // Ref array to store video elements
  const { t } = useTranslation(); // Initialize translation hook

  // List of local video files and their poster (thumbnail) images
  const videos = [
    {
      id: 1,
      title: t("videoGallery.performansTitle"), // Translated title
      videoSrc: Video1,
      poster: performans
    },
    {
      id: 2,
      title: t("videoGallery.aracTakipTitle"),
      videoSrc: Video2,
      poster: aracTakip
    },
    {
      id: 3,
      title: t("videoGallery.hygiaMedTitle"),
      videoSrc: Video3,
      poster: hygiaMed
    }
  ];

  const handleThumbnailClick = (videoId) => {
    videoRefs.current.forEach((video, index) => {
      if (video && index !== videoId) {
        video.pause();
        video.muted = true;
        video.currentTime = 0;
      }
    });

    setClickedVideo(videoId);
    if (videoRefs.current[videoId]) {
      videoRefs.current[videoId].muted = false;
      videoRefs.current[videoId].play();
    }
  };

  const handleVideoPause = (videoId) => {
    if (videoRefs.current[videoId]) {
      videoRefs.current[videoId].pause();
      videoRefs.current[videoId].muted = true;
    }
  };

  return (
    <div className="video-gallery py-5">
      <h2 className="text-center">
        {t('videoGallery.title')}
      </h2>
      <p className="text-center mb-5">
        {t('videoGallery.description')}
      </p>

      <div className="video-grid">
        {videos.map((video, index) => (
          <div key={video.id} className="video-thumbnail" onClick={() => handleThumbnailClick(video.id)}>
            {clickedVideo === video.id ? (
              <video
                ref={(el) => (videoRefs.current[video.id] = el)}
                width="100%"
                height="200"
                controls
                onPause={() => handleVideoPause(video.id)}
                autoPlay
                muted={false}
              >
                <source src={video.videoSrc} type="video/mp4" />
                {t('videoGallery.notSupported')}
              </video>
            ) : (
              <video
                ref={(el) => (videoRefs.current[video.id] = el)}
                width="100%"
                height="200"
                poster={video.poster}
                muted
                preload="none"
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default VideoGallery;
